<template>
    <div class="reviews__container">
        <h2 class="reviews__title"><span>{{ $t('reviews.title.span-1') }}</span>{{ $t('reviews.title.span-2') }}</h2>
    </div>
    <div class="reviews__inner">
        <swiper :speed=750 :modules="modules" :centeredSlides="false" :centeredSlidesBounds="true" :spaceBetween="20"
            :slidesPerView=1 :navigation="{
                prevEl: prev,
                nextEl: next,
            }"
            :breakpoints="{ 1: { slidesPerView: 1 }, 550: { slidesPerView: 1.5 }, 720: { slidesPerView: 2.5 }, 1366: { slidesPerView: 3 } }"
            :slides-per-view="3.2" class="reviews__slider">
            <swiper-slide v-for="item in reviews" :key="item.id">
                <div class="reviews__item item-reviews">
                    <div class="item-reviews__row">
                        <div class="item-reviews__rating">
                            <div class="item-reviews__rating-title">{{ item.attributes.title }}</div>
                            <svg v-for="n in item.attributes.rating" :key="n" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                    fill="#FF8800" stroke="#FF8800" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />{{ n }}
                            </svg>
                        </div>
                        <div class="item-reviews__inner">
                            <div class="item-reviews__data">{{ getDate(item.attributes.createdDate) }}</div>
                            <div class="item-reviews__name">{{ item.attributes.reviewerNickname }}</div>
                        </div>
                    </div>
                    <div class="item-reviews__text">
                        {{ item.attributes.body }}
                    </div>
                </div>
            </swiper-slide>
            <div class="reviews__controll">
                <div ref="prev" class="swiper-button-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15 6L9 12L15 18" stroke="#4D2186" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <div ref="next" class="swiper-button-next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 18L15 12L9 6" stroke="#4D2186" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
        </swiper>
    </div>
</template>
<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import axios from 'axios'

import 'swiper/scss';

export default {
    name: "ReviewsComponent",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        const prev = ref(null);
        const next = ref(null);


        return {
            prev,
            next,
            modules: [Navigation],
        };
    },
    data() {
        return {
            reviews: []
        }
    },
    mounted() {
        axios
            .get('https://bibig.app/auth/getToken/index.php')
            .then((response) => {
                this.reviews = JSON.parse(response.data).data
            })
    },
    methods: {
        getDate(date) {
            const ms = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
            const day = new Date(date).getDate()
            const month = ms[new Date(date).getMonth()]
            return day + " " + month

        }
    }
};
</script>
<style lang="scss">
@use "sass:math";
@import "src/assets/style";

.reviews {
    padding-top: rem(140);

    @media screen and (max-width:$tablet) {
        padding-top: rem(100);
    }

    @media screen and (max-width:$mobileSmall) {
        padding-top: rem(80);
    }

    &__title {
        margin-bottom: em(60, 50);

        @media screen and (max-width:$looptop) {
            margin-bottom: em(60, 38);
        }

        @media screen and (max-width:$tablet) {
            margin-bottom: em(40, 32);
        }

        @media screen and (max-width:$mobileSmall) {
            margin-bottom: em(24, 28);
        }
    }

    &__controll {
        display: flex;
        column-gap: rem(20);
        justify-content: flex-end;

        @media screen and (max-width:991px) {
            display: none;
        }

        @media screen and (max-width:550px) {
            display: flex;
        }
    }

    &__inner {
        max-width: 106rem;
        margin: 0 auto;

        @media screen and (max-width:1696px) {
            padding: 0 0 0 rem(math.div($containerPadding, 2));
        }

        @media screen and (max-width:550px) {
            padding: 0 rem(math.div($containerPadding, 2));
        }
    }

    .swiper {
        overflow: visible;
        min-height: 500px;
    }

    .swiper-wrapper {
        margin-bottom: rem(40);
    }
}

.item-reviews {
    max-width: 552px;
    min-height: 325px;
    border-radius: 8px;
    background: #F2F1F7;
    padding: rem(20);

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(25);
    }

    &__rating-title {
        color: #0E0E0E;
        font-size: rem(18);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: em(14, 20);

        @media screen and (max-width:$mobileSmall) {
            font-size: rem(18);
            margin-bottom: em(10, 18);
        }
    }

    &__inner {
        font-size: rem(18);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #7A797F;
        text-align: right;

        @media screen and (max-width:$mobileSmall) {
            font-size: rem(16);
        }
    }

    &__text {
        font-size: rem(18);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #0E0E0E;
        text-align: left;

        @media screen and (max-width:$tablet) {
            font-size: rem(16);
        }
    }
    &__rating{
        text-align: left;
    }
}

.reviews__slider .swiper-button-prev,
.reviews__slider .swiper-button-next {
    border-radius: 26px;
    background: #E7DEF0;
    display: flex;
    padding: 13px 15px 14px 15px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.6s ease 0s;

    @media (any-hover: hover) {
        &:hover {
            background: #4D2186;

            svg path {
                stroke: white;
            }
        }
    }
}
</style>