<template>
    <div class="feed-form">
        <div class="feed-form__container">
            <div class="feed-form__row">
                <div class="feed-form__inner">
                    <h2 class="feed-form__title">{{ $t('feed.title.span-1') }}<span>{{ $t('feed.title.span-2') }}</span>
                    </h2>
                    <form @submit.prevent="feed" class="feed-form__form" method="post" action="">
                        <input v-model="name" :placeholder="$t('feed.form.name')" type="text" name="name">
                        <input v-model="tel" :placeholder="$t('feed.form.tel')" v-mask="selectedPhoneMask"
                            @blur="clearInput" type="tel" name="tel">
                        <input v-model="email" :placeholder="$t('feed.form.email')" type="email" name="email">
                        <textarea v-model="comment" :placeholder="$t('feed.form.comment')" name="comment"
                            id="comment"></textarea>
                        <button type="submit">{{ $t('feed.form.button') }}</button>
                    </form>
                </div>
                <div class="feed-form__animation-inner">
                    <!-- <div class="feed-form__animation-elipse"></div> -->
                    <div v-if="isLocale === 'ru'" class="feed-form__animation">
                        <lottie-player src="https://lottie.host/ee4648f8-fdd8-4f5c-9228-3555d580fa0d/Qjj9hYgaLl.json"
                            background="transparent" speed="1" direction="1" mode="normal" loop autoplay>
                        </lottie-player>
                        <img class="feed-form__qr" src="../assets/img/qr.svg" alt="qr">
                    </div>
                    <div v-else class="feed-form__animation">
                        <lottie-player src="https://lottie.host/828f0745-a1e6-44b0-92bd-7ab0da77100f/mhfVeLgso3.json"
                            background="transparent" speed="1" direction="1" mode="normal" loop autoplay>
                        </lottie-player>
                        <img class="feed-form__qr" src="../assets/img/qr.svg" alt="qr">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import json from '../assets/phone-codes.json'
export default {
    name: "FeedFormComponent",
    components: {
    },
    data() {
        return {
            name: "",
            tel: "",
            email: "",
            comment: "",
            myJson: json,
            selectedPhoneMask: "",
        }
    },
    computed: {
        isLocale: function () { return this.$store.getters.activeLanguage; }
    },
    watch: {
        tel(newVal) {
            this.selectedPhoneMask = this.getPhoneMask(newVal);
        }
    },
    methods: {
        feed: function () {
            let data = {
                name: this.name,
                tel: this.tel,
                email: this.email,
                comment: this.comment,
            }
            console.log(data)
            this.$store.dispatch('sendEmail', data)
        },
        getPhoneMask(phoneNumber) {
            let selectedMask
            const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

            for (let i = 0; i < this.myJson.length; i++) {
                if (cleanedPhoneNumber.length === this.myJson[i].mask.match(/#/g) || [].length + 1 && cleanedPhoneNumber.startsWith(this.myJson[i].mask.replace(/\D/g, ""))) {
                    selectedMask = this.myJson[i].mask;
                }
            }

            console.log(selectedMask)
            if (selectedMask == undefined) {
                return "##########"
            }
            return selectedMask
        },
        clearInput() {
            if (this.tel === "" || this.tel.length < this.selectedPhoneMask.length) {
                this.tel = "";
            }
        }
    }

};
</script>
<style lang="scss">
@import "src/assets/style";

.feed-form {
    height: 100%;
    margin-bottom: rem(106);
    padding-top: rem(140);

    @media screen and (max-width:$tablet) {
        padding-top: rem(100);
    }

    @media screen and (max-width:$mobileSmall) {
        padding-top: rem(80);
    }

    &__animation-inner {
        position: relative;
        height: auto;
        width: 700px;

        @media screen and (max-width:1235px) {
            max-height: 613px;
            height: calc($sizeBlock * 27);
            width: 100%;
        }

        @media screen and (max-width:$mobileSmall) {
            max-height: 336px;
        }
    }

    &__qr {
        position: absolute;
        top: -19px;
        right: -131px;
        z-index: 3;
        max-width: 205px;
        height: auto;
        width: calc($sizeBlock * 8);
        filter: drop-shadow(10px 0px 30px rgba(12, 5, 31, 0.06));

        @media screen and (max-width:$looptop) {
            max-width: 136px;
            right: -87px;
        }

        @media screen and (max-width:1235px) {
            display: none;
        }
    }

    &__animation {
        position: absolute;
        top: -58px;
        z-index: 2;
        left: - percent(130, 755);
        max-height: 755px;
        height: calc($sizeBlock * 27);

        @media screen and (max-width:1696px) {
            left: - percent(100, 750);
        }

        @media screen and (max-width:1433px) {
            max-height: 500px;
            top: percent(300, 700);
            left: percent(50, 750);
        }

        @media screen and (max-width:$looptop) {
            left: percent(100, 750);
        }

        @media screen and (max-width:1235px) {
            max-height: 613px;
            height: calc($sizeBlock * 30);
            top: 0;
            max-height: 613px;
            margin: 0 auto;
            left: unset;
            width: 100%;
        }

        @media screen and (max-width:$mobileSmall) {
            max-height: 336px;
        }
    }

    &__animation-elipse {
        width: 802px;
        height: 802px;
        border-radius: 802px;
        background: rgba(229, 219, 251, 0.60);
        filter: blur(98.5px);
        position: absolute;
        z-index: -1;

        @media screen and (max-width:1235px) {
            display: none;
        }
    }

    &__form {
        width: 409px;

        @media screen and (max-width:1235px) {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: rem(60);
        margin-bottom: em(60, 50);

        @media screen and (max-width:$looptop) {
            margin-bottom: em(60, 38);
        }

        @media screen and (max-width:$tablet) {
            margin-bottom: em(40, 32);
        }

        @media screen and (max-width:$mobileSmall) {
            margin-bottom: em(24, 28);
        }
    }

    input {
        display: inline-block;
        width: 100%;
        padding: 12px 10px;
        border-bottom: 1px solid #B0B0B0;
        margin-bottom: rem(16);

        &::placeholder {
            color: #BEBEBE;
            font-size: rem(16);
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            font-family: "Sen", sans-serif;
        }

        &:focus {
            border-bottom: 1px solid $accentDark;
            outline: 0;
        }


    }

    textarea {
        margin-bottom: rem(26);
        height: 93px;
        display: inline-block;
        width: 100%;
        padding: 12px 10px;
        border-bottom: 1px solid #B0B0B0;
        resize: none;

        &::placeholder {
            color: #BEBEBE;
            font-size: rem(16);
            font-style: normal;
            font-weight: 400;
            vertical-align: bottom;
            line-height: 140%;
            font-family: "Sen", sans-serif;
        }

        &:focus {
            border-bottom: 1px solid $accentDark;
            outline: 0;
        }
    }

    button {
        display: inline-block;
        border-radius: 60px;
        background: #4D2186;
        padding: 14px 40px;
        width: 100%;
        max-width: 409px;

        @media screen and (max-width:1235px) {
            float: inline-end;
        }

        transition: all 0.5s ease 0s;

        @media (any-hover: hover) {
            &:hover {
                background: #3D1A6A;
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width:1235px) {
            flex-direction: column;
            row-gap: rem(100);
        }

        @media screen and (max-width:$mobile) {
            row-gap: rem(50);
        }
    }
}
</style>