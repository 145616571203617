import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style.scss'
import { languages } from "./i18n/index.js"
import { defaultLocale } from "./i18n/index.js"
import { createI18n } from 'vue-i18n'
import VueTheMask from 'vue-the-mask'


const localStorageLang = "en"

const mes = Object.assign(languages)
const i18n = createI18n({
    legacy: false,
    locale: localStorageLang || defaultLocale,
    fallbackLocale: "en",
    messages: mes
})


createApp(App).use(store).use(router).use(i18n).use(VueTheMask).mount('#app')
