<template>
    <div id="burger" :class="{ 'active': isBurgerActive }" @click.prevent="toggle">
        <slot>
            <button type="button" class="burger-button" title="Menu">
                <span class="hidden">Toggle menu</span>
                <span class="burger-bar burger-bar--1"></span>
                <span class="burger-bar burger-bar--2"></span>
                <span class="burger-bar burger-bar--3"></span>
            </button>
        </slot>
    </div>
</template>
<script>

export default {
    computed: {
        isBurgerActive() {
            return this.$store.getters.NAV;
        },
    },
    methods: {
        toggle() {
            this.$store.commit('toggleNav')
        }
    }
}
</script>
<style lang="scss">
@import "src/assets/style";

.hidden {
    visibility: hidden;
}

button {
    cursor: pointer;
}

/* remove blue outline */
button:focus {
    outline: 0;
}

.burger-button {
    position: relative;
    display: block;
    z-index: 999;
    border: 0;
    border-radius: 0;
    border-radius: 10px;
    background: rgba(246, 245, 249, 0.92);
    backdrop-filter: blur(7.5px);
    width: 46px;
    height: 40px;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1);
}

.burger-bar {
    background-color: #4D2186;
    position: absolute;
    margin: 0 7px;
    top: 50%;
    left: 0;
    right: 0px;
    height: 4px;
    border-radius: 40px;
    width: auto;
    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165, .84, .44, 1), opacity .3s cubic-bezier(.165, .84, .44, 1), background-color .6s cubic-bezier(.165, .84, .44, 1);
}

.burger-bar--1 {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
}

.burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(1);
}

.burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
}

.burger-bar--3 {
    transform: translateY(9px);
}

#burger {
    @media screen and (max-width:$mobile) {
        padding-right: rem(16);
    }
}

#burger.active .burger-button {
    transform: rotate(-180deg);
    background: transparent;
}

#burger.active .burger-bar {
    background-color: #4D2186;
}

#burger.active .burger-bar--1 {
    transform: rotate(45deg)
}

#burger.active .burger-bar--2 {
    opacity: 0;
}

#burger.active .burger-bar--3 {
    transform: rotate(-45deg)
}

// .burger-button::before {
//     content: "";
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 10px;
//     background: rgba(246, 245, 249, 0.92);
//     backdrop-filter: blur(7.5px);
//     width: 46px;
//     height: 38px;
//     position: absolute;
//     top: 0;
//     left: 0;
// }
</style>