<template>
    <div class="sidebar">
        <transition name="slide-backdrop" mode="out-in">
            <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        </transition>
        <transition @enter="enter" @leave="leave" name="slide" mode="out-in">
            <div v-if="isPanelOpen" class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
import { gsap } from 'gsap';
export default {
    computed: {
        isPanelOpen() {
            return this.$store.getters.NAV;
        },
    },
    methods: {
        closeSidebarPanel() {
            if (this.isPanelOpen) {
                this.$store.commit('toggleNav');
            }
        },
        enter(el, done) {

            gsap.to(el, {
                duration: 0.2,
                x: 0,
                onComplete: done
            })
        },
        leave(el, done) {
            gsap.to(el, {
                duration: 0.7,
                x: 0,
                ease: 'elastic.inOut(2.5, 1)'
            })
            gsap.to(el, {
                duration: 0.2,
                x: "100%",
                onComplete: done
            })
        }
    },
}
</script>
<style lang="scss">
@import "src/assets/style";

.slide-backdrop-enter-active,
.slide-backdrop-leave-active {
    transition: opacity 0.5s ease;
}

.slide-backdrop-enter-from,
.slide-backdrop-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transform: translateX(100%);
}

.sidebar-backdrop {
    background-color: rgba(0, 0, 0, .4);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 3;
}

.sidebar-panel {
    overflow-y: auto;
    background: #FFF;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: rem(118) rem(15) rem(30) rem(15);
    width: 100%;
}
</style>